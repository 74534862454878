var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter justify-center items-center",
    },
    [
      _c(
        "div",
        { staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4" },
        [
          _c(
            "vx-card",
            [
              _c("div", { staticClass: "vx-card__title mb-6" }, [
                _c("h2", { staticClass: "text-center" }, [
                  _vm._v("We are launching soon"),
                ]),
              ]),
              _c("img", {
                staticClass: "mx-auto mb-2",
                attrs: {
                  src: require("@/assets/images/pages/rocket.png"),
                  alt: "coming-soon",
                  width: "150",
                },
              }),
              _c(
                "div",
                { staticClass: "count-down text-center" },
                [
                  _c("countdown", {
                    attrs: {
                      time:
                        new Date("03/09/2021 00:00:00").getTime() -
                        new Date().getTime(),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("div", { staticClass: "single-counter" }, [
                              _c("span", { staticClass: "timer" }, [
                                _vm._v(_vm._s(props.days)),
                              ]),
                              _c("span", [_vm._v("Days")]),
                            ]),
                            _c("div", { staticClass: "single-counter" }, [
                              _c("span", { staticClass: "timer" }, [
                                _vm._v(_vm._s(props.hours)),
                              ]),
                              _c("span", [_vm._v("Hours")]),
                            ]),
                            _c("div", { staticClass: "single-counter" }, [
                              _c("span", { staticClass: "timer" }, [
                                _vm._v(_vm._s(props.minutes)),
                              ]),
                              _c("span", [_vm._v("Minutes")]),
                            ]),
                            _c("div", { staticClass: "single-counter" }, [
                              _c("span", { staticClass: "timer" }, [
                                _vm._v(_vm._s(props.seconds)),
                              ]),
                              _c("span", [_vm._v("Seconds")]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("vs-divider", { attrs: { position: "center" } }, [
                _vm._v("Subscribe"),
              ]),
              _c(
                "div",
                { staticClass: "subscription" },
                [
                  _c("span", [
                    _vm._v(
                      "If you would like to be notified when our app is live, Please subscribe to our mailing list."
                    ),
                  ]),
                  _c("vs-input", {
                    staticClass: "w-full mb-4",
                    attrs: { type: "email", "label-placeholder": "Email" },
                    model: {
                      value: _vm.value1,
                      callback: function ($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1",
                    },
                  }),
                  _c("vs-button", { staticClass: "w-full" }, [
                    _vm._v("Subscribe"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }